<template>
  <div class="auto-login" :class="{ 'in-iframe': isIframe }">
    <div class="wrap">
      <img src="../../assets/images/logo.png" alt="">
      <h6>{{$consts.SLOGAN_SHORT}}</h6>
      <p v-if="error !== ''" class="error">ADJUSTRD授权失败：{{error}}</p>
      <p v-else>ADJUSTRD授权登录中，请稍后...</p>
      <div v-if="error !== ''" class="opera">
        <Button type="solid" theme="function" size="big" round @click="toLogin">重新登录</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { loginByToken } from '@/api/user'
import BaseUser from '@/components/base/BaseUser'

export default {
  extends: BaseUser,
  data () {
    return {
      error: '',
      isIframe: window.isIframe
    }
  },
  methods: {
  },
  created () {
    loginByToken({
      accessToken: this.$route.query.access_token,
      timestamp: this.$route.query.timestamp,
      digest: this.$route.query.digest
    })
      .then(() => {
        setTimeout(() => {
          if (!isIframe) {
            // 非iframe登录，直接跳转
            if (this.$route.query.payload != null && this.$route.query.payload !== '') {
              window.location.href = this.$route.query.payload
              return
            }
            this.$router.push('/').then().catch()
          } else {
            // iframe登录，发送消息，关闭窗口
            window.parent.postMessage({
              directive: 'loginSuccess'
            }, '/')
          }
        }, 1500)
      })
      .catch(({ message }) => {
        this.error = message
      })
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.auto-login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &.in-iframe {
    background-color: #fff;
    .wrap {
      margin-top: -30px;
      img {
        width: 200px;
      }
    }
  }
  .wrap {
    width: 500px;
    margin-top: -150px;
    text-align: center;
    img {
      width: 350px;
    }
    h6 {
      font-size: 18px;
      margin: 0;
    }
    p {
      margin: 10px 0 0 0;
      font-size: 14px;
      color: $font-color-second;
    }
    .error {
      color: $font-color-red;
    }
    .opera {
      margin-top: 20px;
    }
  }
}
</style>
